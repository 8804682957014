import axios from "axios";
import camelKeys from "camelcase-keys";
import { getBaseHeaders, getHeadersWithContentType } from "../infrastructure/http/apiHeaderUtility";
import { Country } from "src/components/Context/CountryContext";
import {
  GetProductResponse,
  GetPromotionsResponse,
  GetPurchaseOrdersResponse,
  GetInventoryTransactionsResponse,
} from "src/models/product";
import { GetAppDataResponse, GetListsResponse, GetProductListProductResponse } from "src/models/appData";
import { PaginatedSearchResults, SearchResponse, SearchResult, SearchResults } from "src/models/search";
import { MPPricingHistoryResponse } from "src/models/product/MPPricingHistoryResponse";
import { JBPricingHistoryResponse } from "src/models/product/JBPricingHistoryResponse";
import { CreateMessageModel } from "src/validationSchemas";
import { MessageSearchOptions } from "src/models/messaging/MessageSearchOptions";

const getSearchAsync = async (
  query: string,
  jbPage: number,
  mpPage: number,
  pageSize: number,
  country: Country
): Promise<SearchResponse> => {
  const headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_APIM_URL}/Search?q=${query}&jbPage=${jbPage}&mpPage=${mpPage}&pageSize=${pageSize}`,
        {
          headers,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getSearchMessagingProductsAsync = async (
  query: string,
  page: number,
  pageSize: number,
  country: Country
): Promise<SearchResults> => {
  const headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/Search/Messaging?q=${query}&page=${page}&pageSize=${pageSize}`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getSearchMessagingProductsBySkusAsync = async (
  skus: string[],
  country: Country,
  page: number,
  pageSize: number
): Promise<PaginatedSearchResults> => {
  const headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/Search/Messaging/BySkus`, JSON.stringify({ skus, page, pageSize }), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getProductAsync = async (sku: string, country: Country, locationId?: number): Promise<GetProductResponse> => {
  const headers = await getHeadersWithContentType(country);
  var locationString = locationId ? `&locationId=${locationId}` : "";
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/product?sku=${sku}${locationString}`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMPPricingHistoryAsync = async (sku: string, country: Country): Promise<MPPricingHistoryResponse> => {
  const headers = await getHeadersWithContentType(country);

  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/PriceHistory/MP/${sku}`, { headers })
      .then((response) => {
        resolve(camelKeys(response.data));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getJBPricingHistoryAsync = async (sku: string, country: Country): Promise<JBPricingHistoryResponse[]> => {
  const headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/PriceHistory/JB/${sku}`, { headers })
      .then((response) => {
        resolve(camelKeys(response.data));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getBrandImageAsync = async (brandName: string, country: Country): Promise<string> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/BrandImage`, {
        params: { brandName },
        headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postBrandImageAsync = async (brandName: string, image: any, country: Country) => {
  const formData = new FormData();
  formData.append("brandName", brandName);

  if (image) {
    formData.append("image", image);
  }

  var headers = await getBaseHeaders(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/BrandImage`, formData, {
        headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postWasPricesAsync = async (wasPrices, country) => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/WasPriceOverride`, JSON.stringify(wasPrices), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const patchProductAsync = async (patch, sku, country) => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .patch(`${process.env.REACT_APP_APIM_URL}/Product/${sku}`, patch, {
        headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getInventoryTransactionsAsync = async (
  sku: string,
  locationId: number,
  country: Country
): Promise<GetInventoryTransactionsResponse[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_APIM_URL}/Inventory/Transactions?sku=${sku}&locationId=${locationId}&isActiveOnly=true`,
        {
          headers,
        }
      )
      .then((response) => {
        resolve(camelKeys(response.data || [], { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPurchaseOrdersAsync = async (sku: string, country: Country): Promise<GetPurchaseOrdersResponse[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/PurchaseOrders/Sku/${sku}?status=Released`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPromosAsync = async (sku: string, locationId: number, country: Country): Promise<GetPromotionsResponse[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/Product/${sku}/Promotions?LocationId=${locationId}`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys<GetPromotionsResponse[]>(response.data || [], { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPinnedProducts = async (country: Country): Promise<SearchResult[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/Users/Products/Pinned/Details`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postPinnedProducts = async (pinnedProducts: string[], country: Country) => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/Users/Products/Pinned`, JSON.stringify(pinnedProducts), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMyLists = async (country: Country): Promise<GetListsResponse[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/Users/Products/Lists`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMyListDetails = async (name: string, country: Country): Promise<GetProductListProductResponse[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/Users/Products/Lists/Details?name=${name}`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postMyListItem = async (requestObject: any, country: Country) => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/Users/Products/Lists/Items`, JSON.stringify(requestObject), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteMyList = async (requestObject: any, country: Country): Promise<void> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise<void>((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_APIM_URL}/Users/Products/Lists`, {
        headers,
        data: JSON.stringify(requestObject),
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteMyListItem = async (requestObject: any, country: Country) => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_APIM_URL}/Users/Products/Lists/Items`, {
        headers,
        data: JSON.stringify(requestObject),
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getInitialAppData = async (country: Country): Promise<GetAppDataResponse> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/AppData`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getReleaseNotes = async (country: Country): Promise<any[]> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/ReleaseNotes`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getLatestMessagesAsync = async (country: Country): Promise<CreateMessageModel[]> => {
  const headers = await getHeadersWithContentType(country);
  const url = `${process.env.REACT_APP_APIM_URL}/Messages/Recent`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMessageByIdAsync = async (id: string, country: Country): Promise<CreateMessageModel> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_APIM_URL}/Messages/${id}`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMessagesBySearchTextAsync = async (searchtext: string, country: Country): Promise<CreateMessageModel[]> => { 
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_APIM_URL}/Messages/FindMessagesBySkuAndName?searchtext=${encodeURIComponent(searchtext)}`, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};


const postMessageTemplateCreateAsync = async (requestObject: any, country: Country): Promise<any> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/Messages`, JSON.stringify(requestObject), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const putMessageTemplateUpdateAsync = async (id: string, requestObject: any, country: Country): Promise<any> => {
  var headers = await getHeadersWithContentType(country);
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_APIM_URL}/Messages/${id}`, JSON.stringify(requestObject), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postMessageImageAsync = async (image: File, country: Country): Promise<any> => {
  const formData = new FormData();
  formData.append("image", image);

  var headers = await getBaseHeaders(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/Messages/Image`, formData, {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postMessageImageCopyAsync = async (image: any, country: Country): Promise<any> => {
  var headers = await getBaseHeaders(country);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_APIM_URL}/Messages/Image/Copy`, JSON.stringify(image), {
        headers,
      })
      .then((response) => {
        resolve(camelKeys(response.data, { deep: true }));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getSearchAsync,
  getSearchMessagingProductsAsync,
  getSearchMessagingProductsBySkusAsync,
  getProductAsync,
  getBrandImageAsync,
  postBrandImageAsync,
  postWasPricesAsync,
  getPurchaseOrdersAsync,
  getPromosAsync,
  getInventoryTransactionsAsync,
  patchProductAsync,
  getPinnedProducts,
  postPinnedProducts,
  getMyLists,
  getMyListDetails,
  postMyListItem,
  deleteMyList,
  deleteMyListItem,
  getInitialAppData,
  getReleaseNotes,
  getMPPricingHistoryAsync,
  getJBPricingHistoryAsync,
  getLatestMessagesAsync,
  getMessageByIdAsync,
  postMessageTemplateCreateAsync,
  putMessageTemplateUpdateAsync,
  postMessageImageAsync,
  postMessageImageCopyAsync,
  getMessagesBySearchTextAsync
};
