import { Box, Grid, GridProps } from "@mui/material";
import { GetProductResponse } from "src/models/product";

type ProductDetailsProps = GridProps & {
  product: GetProductResponse;
  showTitle?: boolean;
};

export const ProductDetails: React.FC<ProductDetailsProps> = ({ product, showTitle, ...rest }: ProductDetailsProps) => {
  return (
    <>
      {showTitle && (
        <Box component="h3" marginBottom={(theme) => `${theme.spacing(1)} !important`}>
          Product Details
        </Box>
      )}

      <Grid container {...rest}>
        <Grid item xs={5}>
          Format
        </Grid>
        <Grid item xs={7}>
          {product.format}
        </Grid>

        <Grid item xs={5}>
          Barcode
        </Grid>
        <Grid item xs={7}>
          {product.barcode}
        </Grid>

        <Grid item xs={5}>
          Brand
        </Grid>
        <Grid item xs={7}>
          {product.brand}
        </Grid>

        <Grid item xs={5}>
          Department
        </Grid>
        <Grid item xs={7}>
          {product.department}
        </Grid>

        <Grid item xs={5} className="last">
          Department ID
        </Grid>
        <Grid item xs={7} className="last">
          {product.departmentId}
        </Grid>
      </Grid>
    </>
  );
};
